import { useEffect, useRef, useState } from "react";
import Header from "../components/header";
import "./styles.scss";
import { IoIosArrowForward } from "react-icons/io";
import {
  Button,
  Collapse,
  CollapseProps,
  Form,
  Input,
  message,
  Upload,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import TextArea from "antd/es/input/TextArea";
import { SlCloudUpload } from "react-icons/sl";
import Footer from "../components/footer";
import PrefixSelector from "../components";

const Careers = (props: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<any>(null);
  const [fileName, setFileName] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const beforeUpload = (file: any) => {
    const isPdfOrDoc =
      file.type === "application/pdf" ||
      file.type === "application/msword" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    if (!isPdfOrDoc) {
      message.error("You can only upload PDF, DOC, or DOCX files!");
      return Upload.LIST_IGNORE;
    }

    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error("File must be smaller than 10MB!");
      return Upload.LIST_IGNORE;
    }

    setFileName(file.name);
    // return true;
  };
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <h5>How many hours can I work?</h5>,
      children: (
        <p>
          How much you work is up to you. With Elder you can choose which
          placements you’d like to apply for, and work with multiple clients at
          once to create a weekly work schedule that meets your needs. This
          gives you freedom to apply your skills and experience as you see fit,
          and the flexibility to take on additional placements if you want them.
        </p>
      ),
    },
    {
      key: "2",
      label: <h5>What support does Elders Caring Arms provide?</h5>,
      children: (
        <p>
          Our intuitive platform helps self-employed carers find work that meet
          their needs, and communicate with the families they match with. Our
          platform also makes it easier to apply for and manage work, book
          breaks, and find the right support in our help centre, or from
          dedicated team who are available by phone or through live chat. Elder
          also takes care of the tricky stuff, such as managing your payments,
          and organising cover when you take a break. We also provide ongoing
          support through the Elder Hub app and emergency helpline for when
          you’re on placement.
        </p>
      ),
    },
    {
      key: "3",
      label: <h5>Will I need to work overnight?</h5>,
      children: (
        <p>
          We don’t currently offer overnight shifts to hourly carers. If you’d
          like to work overnight, you may be interested in our live-in care
          placements.{" "}
        </p>
      ),
    },
  ];
  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      const api = "baseUrl/careers/post";
      const file = values.resume[0].originFileObj;
      console.log(file, "file");
      let mobile = `${values.code} ${values.phoneNumber}`;
      let obj = {
        name: values.firstName + values?.lastName,
        email: values?.email,
        phoneNumber: values?.phoneNumber ? mobile : "",
        applyingPost: values?.applyingPost,
        resume: file,
        message: values?.message,
      };
      let response: any = await props?.post(api, obj);
      if (response.status) {
        message.success("Success");
      } else {
        message.error("oops.something gone wrong.");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };
  const careers = [
    {
      head: "Enjoy flexible working",
      description:
        "Set your preferences, choose when and where you work, and book breaks with ease.",
    },
    {
      head: "Get a competitive salary",
      description:
        "We’re looking for people who are passionate about making a difference in people’s lives.",
    },
    {
      head: "Make a real difference",
      description:
        "Help vulnerable people get the quality care they need, in their own homes.",
    },
    {
      head: "Access support",
      description:
        "Get support around-the-clock via the daily newsfeed and help centre.",
    },
  ];
  return (
    <>
      <Header />
      <div className="py-2 py-md-5">
        <Container>
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> Careers
          </div>
          <Row className="g-2 g-md-4">
            <Col md={6}>
              <div className="d-flex flex-column  justify-content-center h-100 ">
                <div className="section2_text2 text-start ">
                  A Career in Elder Caring Arms
                </div>
                <p className="section2_text3 my-2">
                  {" "}
                  Are you passionate about making a difference in people’s
                  lives? If you’re looking for a fulfilling career in in-home
                  care, we would love to hear from you.
                </p>
                <Button className="my-3 main_button" onClick={scrollToForm}>
                  Apply Now
                </Button>
              </div>
            </Col>
            <Col md={6}>
              <div className="careers_image">
                <img
                  src={require("../assets/images/careers.jpg")}
                  alt="image1"
                />
              </div>
            </Col>
            <div className="my-5">
              <div className="section2_text2">Why Choose Us</div>
              <Row className="g-3">
                {careers.map((item, index) => (
                  <Col md={3}>
                    <div className="careerBox">
                      <div className="careerBox1">
                        <div className="service_text1">{item?.head}</div>
                        <div className="section3_para">{item?.description}</div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
            <div className=" my-2 mt-md-5">
              <p className="section3_para">
                Are you passionate about making a difference in people’s lives?
                If you’re looking for a fulfilling career in in-home care, we
                would love to hear from you. Complete the form below to learn
                about our current opportunities and take the first step towards
                a rewarding career with us.
              </p>
            </div>
            <Col xs={12} md={{ span: 8, offset: 2 }} ref={formRef}>
              <Form onFinish={onFinish}>
                <Row>
                  <Col md={6}>
                    <div className="label">First Name</div>
                    <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                    <div className="label">Email</div>
                    <Form.Item
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                      ]}
                    >
                      <Input size="large" type="email" />
                    </Form.Item>{" "}
                    <div className="label">Position Applying For</div>
                    <Form.Item
                      name="applyingPost"
                      rules={[
                        {
                          required: true,
                          message: "Please enter applying position!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <div className="label">Last Name</div>
                    <Form.Item
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                    <div className="label">Phone Number</div>
                    <Form.Item
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        className="phone_Select"
                        addonBefore={<PrefixSelector />}
                        type="text"
                        onInput={(e) => {
                          e.currentTarget.value = e.currentTarget.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Form.Item>
                    <div className="label">Attach Resume</div>
                    <Form.Item
                      name="resume"
                      valuePropName="fileList"
                      getValueFromEvent={(e) => e && e.fileList}
                      rules={[
                        {
                          required: true,
                          message: "Please upload your resume!",
                        },
                      ]}
                    >
                      <Upload
                        className="uploader"
                        name="file"
                        beforeUpload={beforeUpload}
                        maxCount={1}
                        showUploadList={false}
                      >
                        <Input
                          suffix={<SlCloudUpload />}
                          size="large"
                          readOnly
                          value={fileName}
                          placeholder="PDF or DOCX, max 10MB"
                          style={{ width: "100%" }}
                        />{" "}
                      </Upload>
                    </Form.Item>{" "}
                  </Col>
                  <div className="label">Message</div>
                  <Form.Item name="message">
                    <TextArea rows={4} size="large" maxLength={300} />
                  </Form.Item>
                  <Col xs={12} md={{ span: 3, offset: 9 }}>
                    <Button block className="main_button" loading={isLoading}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className="my-5">
                <Col xs={12} md={{ offset: 2, span: 8 }}>
                  <div className="text-center">
                    <h2>Frequently Asked Questions?</h2>
                    <p className="section3_para">
                      {" "}
                      Care can be confusing, and sometimes difficult to talk
                      about. We’ve answered some common questions below.
                    </p>{" "}
                  </div>
                  <Collapse
                    ghost
                    expandIconPosition="end"
                    size="large"
                    items={items}
                    bordered={false}
                  />
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Careers;
