import { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { IoIosArrowForward } from "react-icons/io";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../components/footer";
import { FaPeopleCarryBox } from "react-icons/fa6";
import { TbEyeUp } from "react-icons/tb";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <Container>
        <div className="py-5 ">
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> About Us
          </div>
          <div className="section2_text2 py-3">
            Discover the Elders caring arms Difference: Unparalleled Home Care
            Grounded in Compassion and Expertise
          </div>
          <Row className="g-2 g-lg-5">
            <Col xs={12} lg={5}>
              <p className="section3_para">
                In today’s society, home care is more than mere convenience;
                it’s a fundamental pillar of healthcare with a profound impact
                on the lives of those it serves. This is especially true at a
                time when the emphasis on the comfort and familiarity of home as
                a factor in health and wellness has never been greater. By
                providing essential health services within the individual’s own
                living space, home care helps bridge the gap between clinical
                health care and personal well-being, catering not just to the
                physical but also the emotional needs of the individual.
              </p>
              <br />
              <h4>Why Choose Elders caring arms?</h4>
              <p className="section3_para">
                Choosing Elders caring arms means entrusting your care to
                dedicated professionals who prioritize your unique needs. We
                offer personalized care plans, a team of experienced and
                compassionate caregivers, and a comprehensive range of services
                to cater to diverse needs. We accept insurance, easing financial
                concerns, and we continually strive to improve and innovate in
                our service delivery. With us, you’re not just getting a
                provider – you’re gaining a partner in health and an extended
                family that cares deeply about your well-being.
              </p>
            </Col>
            <Col xs={12} lg={7}>
              <img
                src={require("../assets/images/aboutus.jpg")}
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "cover" }}
                alt=""
              />
            </Col>

            <Col xs={12} lg={5}>
              <div className="h-100">
                <img
                  src={require("../assets/images/memory.jpg")}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col xs={12} lg={7}>
              <div className="h-100">
                <div className=" d-flex justify-content-center align-items-center flex-column ">
                  <TbEyeUp color="#e08b84" size={80} />
                  <h4 className="mb-2">Our Vision</h4>
                  <p className="section3_para">
                    Our vision is to be the leading choice for home care in
                    Texas, renowned for our patient-centered approach and
                    commitment to exceptional service. We aim to set the
                    standard for home care excellence, fostering a supportive
                    environment that encourages growth, empowerment and enhanced
                    well-being for our clients.
                  </p>
                </div>
                <div className=" d-flex justify-content-center align-items-center flex-column  ">
                  <FaPeopleCarryBox color="#e08b84" size={80} />
                  <h4 className=" mb-2">Our Mission</h4>
                  <p className="section3_para">
                    Our vision is to be the premier provider of senior living
                    services and old age care in India, and we aim to transform
                    the industry through innovative care, employee development
                    and resident lifestyle enhancement. Our values, including
                    integrity, respect, and compassion, form the foundation of
                    our interactions with both our elderly residents and
                    dedicated employees.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default AboutUs;
