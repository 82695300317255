import { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "antd";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import { IoCheckmarkDoneOutline } from "react-icons/io5";
import { TbHeartPlus, TbReportMedical } from "react-icons/tb";
import { FaPeopleRobbery } from "react-icons/fa6";
import { MdOutlineDirectionsCarFilled } from "react-icons/md";
import accomodation from "../assets/images/accomo.webp";
import interraction from "../assets/images/interract.jpg";
import medical from "../assets/images/medicalcare.jpg";
import nursing from "../assets/images/nursing.jpg";
const Fade = require("react-reveal/Fade");

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  const cards = [
    {
      id: 1,
      title: "Accomodation",
      description:
        "All our accommodation rooms are well-furnished and come with plenty of in-room amenities",
      image: accomodation,
    },
    {
      id: 2,
      title: "Interaction Center",
      description:
        "We try and initiate a better understanding of ageing from a social prespective",
      image: interraction,
    },
    {
      id: 3,
      title: "Medical Checkup",
      description:
        "We conduct routine medical-checkup and provide medication to all our elderly patients",
      image: medical,
    },
    {
      id: 4,
      title: "Nursing & Personal Care",
      description:
        "We provide self-contained rooms and 24 hour nursing & personal care for aged person",
      image: nursing,
    },
  ];
  const emergency = [
    "24 X 7 helpline to coordinate emergency support",
    "Emergency response protocol setup for the family",
    "Hospitalization management",
    "Medical emergency fund management",
    "Real time coordination and regular updates to family",
  ];
  const health = [
    "Medical care and coordination helpline",
    "Accompanied visits for medical appointments",
    "Schedule & coordinate diagnostic tests, doctor appointments.",
    "Second opinion from specialists",
    "Surgery/procedure planning",
    "GP Review & health profiling",
    "Medical claims assistance",
  ];
  const social = [
    "Organise leisure & recreation, walks, reading and indoor games",
    "Accompanied social visits ",
    "Opportunities for learning new skills and hobbies",
    "Organise mental & physical wellness activities",
    "Video, online & in-person community meet-ups",
    "Samarth accompanied, customised and group tours for seniors",
    "Opportunities to participate in professional work.",
  ];
  const travel = [
    "Transport & travel planning and arrangement assistance",
    "Assist in packing, moving or relocating",
    "Arrange pick up and drop for local travel",
    "Customised and group tours for seniors",
    "Companion for local & domestic/ international drops",
  ];
  return (
    <div>
      <Header />
      <Container>
        <div className="py-5 ">
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> Services
          </div>
          <div className="section2_text2 py-3">
            Experience a Spectrum of Services: Comprehensive, Customized, and
            Centered on You
          </div>
          <Row className="g-3 g-md-4 ">
            {cards.map((item: any) => {
              return (
                <>
                  <Col md={6} lg={3} xs={12} clas>
                    <Fade bottom>
                      <div className="services_card ">
                        <img
                          src={item?.image}
                          alt="image1"
                          className="services_map_images"
                        />
                        <div className="service_text1 mt-2">{item?.title}</div>
                        <div className="service_text2 mt-2">
                          {item?.description}
                        </div>
                        <Link to="/contact-us">
                          <Button className="mt-3 main_button">
                            Enquire Now
                          </Button>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                </>
              );
            })}
            <Col md={6}>
              <Fade bottom>
                <div className="services_box">
                  <div className="image-container ">
                    <img src={require("../assets/images/erroom.jpg")} alt="" />
                  </div>
                  <div>
                    <div className="d-flex  align-items-center mt-2">
                      <TbReportMedical
                        size={40}
                        color="blue"
                        className="me-2"
                      />
                      <div className="services_text3">Emergency Support</div>
                    </div>
                    {emergency.map((item: any) => (
                      <div className="d-flex mt-3 align-items-center ms-2">
                        <IoCheckmarkDoneOutline
                          color="#f11b4a"
                          className="me-2"
                        />
                        <div className="services_text">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade bottom>
                <div className="services_box">
                  <div className="image-container ">
                    <img src={require("../assets/images/travel.jpg")} alt="" />
                  </div>
                  <div>
                    <div className="d-flex  align-items-center mt-2">
                      <MdOutlineDirectionsCarFilled
                        size={40}
                        color="blue"
                        className="me-2"
                      />
                      <div className="services_text3">Mobility & Travel</div>
                    </div>
                    {travel.map((item: any) => (
                      <div className="d-flex mt-3 align-items-center ms-2">
                        <IoCheckmarkDoneOutline
                          color="#f11b4a"
                          className="me-2"
                        />
                        <div className="services_text">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade bottom>
                <div className="services_box">
                  <div className="image-container">
                    <img
                      src={require("../assets/images/healthsuppport.jpg")}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="d-flex  align-items-center mt-2">
                      <FaPeopleRobbery
                        size={40}
                        color="violet"
                        className="me-2"
                      />
                      <div className="services_text3">Social Engagement</div>
                    </div>
                    {social.map((item: any) => (
                      <div className="d-flex mt-3 align-items-center ms-2">
                        <IoCheckmarkDoneOutline
                          color="#f11b4a"
                          className="me-2"
                        />
                        <div className="services_text">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={6}>
              <Fade bottom>
                <div className="services_box">
                  <div className="image-container">
                    <img
                      src={require("../assets/images/emerencySupport.jpg")}
                      alt=""
                    />
                  </div>
                  <div>
                    <div className="d-flex  align-items-center mt-2">
                      <TbHeartPlus size={40} color="red" className="me-2" />
                      <div className="services_text3">
                        Health & Hospitalisation
                      </div>
                    </div>
                    {health.map((item: any) => (
                      <div className="d-flex mt-3 align-items-center ms-2">
                        <IoCheckmarkDoneOutline
                          color="#f11b4a"
                          className="me-2"
                        />
                        <div className="services_text">{item}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Services;
