import { Col, Container, Row } from "react-bootstrap";
import { FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { RiFacebookFill } from "react-icons/ri";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col md={4} xs={12}>
            <div>
              <img
                src={require("../assets/images/silver-dove-logo.png")}
                className="footerLogo"
                alt="logo"
              />
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="d-flex  flex-column flex-md-row   mt-3 mt-md-0 justify-content-center gap-2">
              {" "}
              <div
                className="label text-white"
                onClick={() => navigate("/about-us")}
              >
                About Us
              </div>
              <div
                className="label text-white"
                onClick={() => navigate("/services")}
              >
                Services
              </div>
              <div
                className="label text-white"
                onClick={() => navigate("/careers")}
              >
                Careers
              </div>
              <div
                className="label text-white"
                onClick={() => navigate("/contact-us")}
              >
                Contact Us
              </div>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <div className="d-flex justify-content-md-end justify-content-start my-3 my-md-0 gap-3">
              <div className="social_icons">
                <FaInstagram size={25} color="white" />
              </div>
              <div className="social_icons">
                <RiFacebookFill size={25} color="white" />
              </div>
              <div className="social_icons">
                <FaXTwitter size={25} color="white" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
