import { Col, Row } from "react-bootstrap";
import "../styles.scss";
import greenArrow from "../../assets/images/arrowgreen.svg";
import medical from "../../assets/images/medicalcare.jpg";
import cozy from "../../assets/images/cozy.jpg";
import memory from "../../assets/images/memory.jpg";
import care from "../../assets/images/careandcompan.jpg";
import { IoArrowForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");

const Section2 = () => {
  const navigate = useNavigate();
  const cards = [
    { id: 1, title: "24/7 ", description: "Medical Care", image: medical },
    { id: 2, title: "Safe and ", description: "Cozy Rooms", image: cozy },
    { id: 3, title: "Memory ", description: "Support Services", image: memory },
    { id: 4, title: "Care and ", description: "Companionship", image: care },
  ];
  const cards2 = [
    {
      id: 1,
      title: "Schedule an ",
      head: "Assessment",
      description: "You can take the first step towards personalized care.",
    },
    {
      id: 2,
      title: "Meet Our ",
      head: "Caregivers",
      description: "Get to know our compassionate and professional caregivers.",
    },
  ];
  return (
    <div className="sections_box">
      <div className=" text-center">
        <div className="section2_text1">How We Meet Your Needs</div>
        <div className="section2_text2">Service We Offer</div>
        <div className="section2_text3 mt-4">
          Explore the wide variety of services we provide dedicated to you.
        </div>
      </div>
      <br />
      <br />
      <Row className="g-4 g-lg-4">
        {cards.slice(0, 2).map((item: any) => (
          <Col md={6} xs={12}>
            <Fade bottom>
              <div className="section2_card1">
                <div>
                  <img
                    src={item?.image}
                    className="section2_images"
                    alt="image1"
                  />
                </div>

                <div className="section_box">
                  <div className="section_box1 ">
                    <div className="section2_text3">{item?.title}</div>
                    <div className="section2_text4">{item?.description}</div>
                  </div>
                  <img src={greenArrow} alt="image1" width="55px" />
                </div>
              </div>
            </Fade>
          </Col>
        ))}

        <Col md={3} className="d-none d-xl-block" />
        {cards2.map((item: any) => (
          <Col md={6} xl={3} xs={12}>
            <Fade bottom>
              <div
                className="section2_card2"
                onClick={() => navigate("/contact-us")}
              >
                <div className="section2_card_box1">
                  <div>
                    <div className="section2_card_text1">{item?.title}</div>
                    <div className="section2_text4">{item?.head}</div>
                  </div>
                  {/* <img src={roseArrow} alt="image1" width="55px" /> */}
                  <div className="rosearrow">
                    <IoArrowForward color="white" size={20} />
                  </div>{" "}
                </div>

                <div className="section2_card_text1 ">{item?.description}</div>
              </div>
            </Fade>
          </Col>
        ))}

        {cards.slice(2, 4).map((item: any) => (
          <Col md={6} xs={12}>
            <Fade bottom de>
              <div className="section2_card1">
                <div>
                  <img
                    src={item?.image}
                    className="section2_images"
                    alt="image1"
                  />
                </div>

                <div className=" section_box ">
                  <div className="section_box1 ">
                    <div className="section2_text3">{item?.title}</div>
                    <div className="section2_text4">{item?.description}</div>
                  </div>
                  <img src={greenArrow} alt="image1" width="55px" />
                </div>
              </div>
            </Fade>
          </Col>
        ))}
      </Row>
      <br />
      <br />
    </div>
  );
};

export default Section2;
