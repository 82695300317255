import { Form, Select } from "antd";
import Country from "./countryCode.json";

const PrefixSelector = ({ countryCode }: any) => {
  return (
    <Form.Item
      name="code"
      noStyle
      rules={[{ required: true, message: "Please input your phone number!" }]}
      className="phone_Select"
    >
      <Select
        style={{
          width: 70,
        }}
        size="small"
        showSearch={true}
        placeholder={"+"}
      >
        {Country.map((item: any) => {
          return (
            <Select.Option key={item.dial_code} value={item.dial_code}>
              {item.dial_code}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default PrefixSelector;
