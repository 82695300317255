import { Col, Row } from "react-bootstrap";
import { TbBulb } from "react-icons/tb";
import { LiaAngellist } from "react-icons/lia";
import { FaPeopleRoof } from "react-icons/fa6";
import { VscGraphLine } from "react-icons/vsc";
import { PiHandshake } from "react-icons/pi";
const Fade = require("react-reveal/Fade");
const Section5 = () => {
  type Card = {
    head: string;
    icon: JSX.Element;
  };
  const cards = [
    { head: "Understanding Needs", icon: <TbBulb size={50} />, delay: 0 },
    {
      head: "Tailoring A Customized Care Plan",
      icon: <LiaAngellist size={50} />,
    },
    {
      head: "Reliable Care Team",
      icon: <FaPeopleRoof size={50} />,
    },
    {
      head: "Complete Personal Care",
      icon: <FaPeopleRoof size={50} />,
    },
    {
      head: "Helping Constant Improvement",
      icon: <VscGraphLine size={50} />,
    },
    {
      head: "Client Centric Approach",
      icon: <PiHandshake size={50} />,
    },
  ];
  return (
    <div className="pb-5 ">
      <div className="section2_text2 text-center">What we assure</div>
      <div className=" px-5">
        <Row>
          {cards.slice(0, 3).map((item: Card, index) => (
            <Col md={4}>
              <Fade bottom>
                <div className="section5_box">
                  <div>{item.icon}</div>
                  <div>{item.head}</div>
                </div>
              </Fade>
            </Col>
          ))}

          {cards.slice(3, 6).map((item: Card, index) => (
            <Col md={4}>
              <Fade bottom>
                <div className="section5_box">
                  <div>{item.icon}</div>
                  <div>{item.head}</div>
                </div>
              </Fade>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Section5;
