import "../styles.scss";

const Section1 = () => {
  return (
    <div className="Section1_Box">
      <div className="section1_text1 mb-5">
        Creating a Safe,Caring,and Joyful
        <br /> Sanctuary for Seniors{" "}
      </div>
      <div className="video_box">
        <video autoPlay muted loop className="video_file">
          <source
            src={require("../../assets/images/section1.mp4")}
            type="video/mp4"
            className="video_file"
          />
        </video>
      </div>

      {/* <div className="section1_roundIcon big_screen">
        <img src={round} alt="icon" />
      </div>
      <div className="section1_roundIcon2 big_screen">
        <img src={round} alt="icon" />
      </div> */}
    </div>
  );
};

export default Section1;
