import { Col, Container, Row } from "react-bootstrap";
import { IoArrowForward } from "react-icons/io5";
const Fade = require("react-reveal/Fade");

const Section3 = () => {
  const cards = [
    {
      id: 1,
      title: "We are here for you ",
      head: "About us",
      description:
        "Our mission is to provide special home care to our clients...",
      delay: 0,
    },
    {
      id: 2,
      title: "Insurance",
      head: " Accepted",
      description:
        "Ease your financial matters with our insurance-friendly policies.",
      delay: 200,
    },
    {
      id: 3,
      title: "Send Your ",
      head: "Referrals",
      description:
        "Help us extend our circle of care by referring somone to us",
      delay: 500,
    },
  ];
  return (
    <div className="sections_box" style={{ backgroundColor: "#91ddcf" }}>
      <Container>
        <Row className="g-3 g-lg-4">
          <Col md={6} xs={12}>
            <div style={{ width: "80%" }}>
              <div className="section2_text1 text-white">Welcome to</div>
              <div className="py-1 py-md-3">
                <img
                  src={require("../../assets/images/silver-dove-logo.png")}
                  alt="logo"
                  className="section3_logo"
                />
              </div>
              <br />

              <p className="section3_para">
                We acknowledge the universal need for quality home care across
                all age groups. Whether dealing with recovery, chronic
                conditions, or the challenges of everyday activities, everyone
                deserves support within their own homes.
              </p>
              <p className="section3_para">
                As your reliable provider of home care,{" "}
                <strong>Elders Caring Arms</strong>, we delivers personalized
                services from dedicated caregivers. We extend our expertise,
                always placing your needs at the forefront and we aim to offer
                peace of mind by ensuring that every elder receives the respect,
                attention, and professional care they deserve.
              </p>
            </div>
          </Col>
          <Col md={6} xs={12}>
            <Fade bottom>
              <div className="section3_box2 h-100">
                <img
                  src={require("../../assets/images/section3.jpg")}
                  alt="image1"
                  style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    objectFit: "cover",
                  }}
                />
              </div>
            </Fade>
            <br />
            <br />
          </Col>
          <div className="d-flex justify-content-center">
            <Row>
              {cards.map((item: any) => (
                <Col md={6} xl={4} className="my-3">
                  <Fade bottom delay={item?.delay}>
                    <div className="section2_card3">
                      <div className="section2_card_box1">
                        <div>
                          <div className="section2_card_text2">
                            {item?.title}
                          </div>
                          <div className="section2_text4">{item?.head}</div>
                        </div>
                        <div className="rosearrow">
                          <IoArrowForward color="white" size={20} />
                        </div>
                      </div>
                      <div className="section2_card_text1 pt-1">
                        {item?.description}
                      </div>
                    </div>
                  </Fade>
                </Col>
              ))}
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Section3;
