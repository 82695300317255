import React, { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import Footer from "../components/footer";
const Resources = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <Container>
        <div className="py-5 ">
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> Resources
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Resources;
