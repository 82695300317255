import { useEffect } from "react";
import Header from "../components/header";
import "./styles.scss";
import { Col, Container, Row } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { Button, Form, Input } from "antd";
import Footer from "../components/footer";
import { Link } from "react-router-dom";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoMailOutline } from "react-icons/io5";
import { PiPhoneCall } from "react-icons/pi";
import { useForm } from "antd/es/form/Form";
import { AiOutlineWhatsApp } from "react-icons/ai";
import PrefixSelector from "../components";

const ContactUs = () => {
  const [form] = useForm();
  const map = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.9194177078515!2d-84.7127661!3d33.8679166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f53b3679e660b1%3A0x7bd7de34831adcc6!2s5655%20Walnut%20Mill%20Ln%2C%20Hiram%2C%20GA%2030141%2C%20USA!5e0!3m2!1sen!2sin!4v1713953210257!5m2!1sen!2sin" width="100%" height="500" style="border:0;border-radius:10px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // const [isLoading, setIsLoading] = useState(false);
  // const onFinish = async (values: any) => {
  //   try {
  //     setIsLoading(true);
  //     const api = "baseUrl/contact/post";
  //     let obj = {
  //       name: values?.name,
  //       address: values?.address,
  //       email: values?.email,
  //       phoneNumber: values?.phoneNumber,
  //       notes: values?.notes,
  //     };
  //     let response: any = await props?.post(api, obj);
  //     if (response.status) {
  //       message.success("Success");
  //     } else {
  //       message.error("oops.something gone wrong.");
  //     }
  //   } catch (err) {
  //     console.log("err", err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  return (
    <div>
      <Header />
      <Container>
        <div className="py-5 ">
          <div className="heading_bredcume">
            Home <IoIosArrowForward /> Contact Us
          </div>
          <Row className="g-2 g-lg-4">
            <Col md={6}>
              <div className="d-flex flex-column  justify-content-center h-100 ">
                <div className="section2_text2 text-start ">Get in touch</div>
                <p className="section2_text3">
                  {" "}
                  We’re open 8am-6pm on weekdays and 9am-5pm on weekends.
                </p>

                <Row className="g-1 ">
                  <Col md={12} lg={6}>
                    <div className="icon_box1">
                      <AiOutlineWhatsApp size={32} color="green" />
                      <div>
                        <a
                          // href={`https://wa.me/832-270-2826`}
                          // target="_blank"
                          // rel="noreferrer"
                          className="label"
                        >
                          (404) 993-5463
                        </a>
                      </div>
                    </div>
                    <div className="icon_box1">
                      <PiPhoneCall size={32} color="#3c4b9e" />
                      <div>
                        <a
                          href="tel:   (404) 993-5463 "
                          target="_blank"
                          rel="noreferrer"
                          className="label"
                        >
                          (404) 993-5463
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col md={12} lg={6}>
                    <div className="icon_box1">
                      <IoMailOutline size={32} color="#000" />{" "}
                      <a
                        href={`mailto:Blessing@silverdovehealthservices.com`}
                        target="_blank"
                        rel="noreferrer"
                        className="label"
                      >
                        Blessing@silverdovehealthservices.com
                      </a>
                    </div>
                    <div className="icon_box1">
                      <HiOutlineOfficeBuilding size={32} color="#ba6c6c" />
                      <div>
                        <div className="label">
                          {" "}
                          5655 walnut mill lane, Powder Springs. GA. 30127
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="my-3">
                  <div className="service_text1">
                    Are you looking for a career in Elders Caring Arms
                  </div>
                  <Link to="/careers">
                    <Button className="mt-3 main_button">Find a career</Button>
                  </Link>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="careers_image ">
                <img
                  src={require("../assets/images/elder-contact.jpg")}
                  alt="image1"
                />
              </div>
            </Col>
            <div className="sections_box">
              <div className="service_text1 mb-4">
                We're here to listen and support you every step of the way.
                Please fill out the form below, our team will be in touch with
                you shortly.
              </div>
              <Row className="g-1 g-md-3 g-lg-5">
                <Col md={6} xs={12}>
                  <div dangerouslySetInnerHTML={{ __html: map }} />
                </Col>
                <Col md={6} xs={12}>
                  <Form form={form}>
                    <div className="label">Name</div>
                    <Form.Item name="name">
                      <Input size="large" placeholder="Your Name" required />
                    </Form.Item>
                    <div className="label">Address</div>
                    <Form.Item name="address">
                      <Input size="large" placeholder="Address" />
                    </Form.Item>
                    <div className="label">Phone</div>
                    <Form.Item name="phone">
                      <Input
                        size="large"
                        className="phone_Select"
                        addonBefore={<PrefixSelector />}
                        type="text"
                        onInput={(e) => {
                          e.currentTarget.value = e.currentTarget.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                    </Form.Item>
                    <div className="label">Email</div>
                    <Form.Item name="email">
                      <Input
                        size="large"
                        placeholder="Email"
                        type="email"
                        required
                      />
                    </Form.Item>
                    <div className="label">Message</div>
                    <Form.Item name="message">
                      <Input.TextArea
                        size="large"
                        rows={3}
                        placeholder="Type your message..."
                      />
                    </Form.Item>

                    <Col md={{ offset: 6, span: 6 }} xs={12}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        // loading={isLoading}
                        style={{ width: "100%" }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Form>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactUs;
