import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./home";
import AboutUs from "./pages/aboutUs";
import ContactUs from "./pages/contactUs";
import Services from "./pages/services";
import Resources from "./pages/resources";
import Careers from "./pages/careers";
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            paddingBlockLG: 10,
            padding: 30,
          },
          Collapse: {
            contentBg: "#f5f5f5",
            headerBg: "#f5f5f5",
          },
          Button: {
            borderRadius: 10,
          },
        },

        token: {
          colorPrimary: "#f11b4a",
          fontFamily: "DMSans-Medium",
          borderRadius: 0,
        },
      }}
    >
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
    </ConfigProvider>
  );
}

export default App;
