import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Fade = require("react-reveal/Fade");

const Section4 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="section4_box">
        <Container>
          <Row>
            <Col md={6}>
              <Fade bottom duration={2000}>
                <div className="column_background">
                  <div className="section2_text1 ">
                    We're Here When You Need Us
                  </div>
                  <div className="section2_text2 ">Our Mission Statement</div>
                  <div className="section2_text3 ">
                    At Elder Caring Arms, our mission is to provide
                    comprehensive, compassionate, and personalized home care to
                    our clients…
                  </div>
                  <div
                    className="aboutus_button"
                    onClick={() => navigate("/about-us")}
                  >
                    About Us
                  </div>
                </div>
              </Fade>
            </Col>
            <Col md={6} />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Section4;
